.callback-alert-modal {
    
    .modal-wrapper {
        padding: 15px 30px;

        @media only screen and (max-width: $scr-xs) {
            padding: 15px;
        }

    }

    &__title {
        margin-bottom: 0;
    }

    &__subtitle {
        font-size: $text-size-s;
        margin-bottom: 0;
    }

    &__info-container {
        max-width: 450px;
        margin: auto;
    }

    &__api-response-container {
        font-size: $text-size-m;
        display: flex;
        margin: 35px auto 0px;
        max-width: 450px;
        
        @media only screen and (max-width: $scr-xs) {
            font-size: $text-size-s;
        }

    }

    &__api-response-field {
        margin-top: 3px;
    }

    &__api-response-field-box {
        border: 1px solid #D5D5D5;
        border-radius: 5px;
        display: flex;
        flex: 1;
        min-height: 100px;
        padding: 10px;
        margin-left: 10px;
    }

    &__api-response-value {

    }

}