/* -- Component -- */
$header-height: 55px;

/* -- Color -- */
$c-white: #FFFFFF;
$c-black: #000000;
$c-body: #ECECEC;
$c-dark-gray: #787878;
$c-light-gray: #A4A4A4;
$c-border-gray: #A4A4A4;
$c-red: #DC3545;
$c-primary-blue: #7D9EE2;
$c-dark-blue: #72A7CF;
$c-primary-purple: #7A67DB;
$c-shadow-black: #00000029;

/* -- Font size -- */
$text-size-xxxl: 48px;
$text-size-xxl: 32px;
$text-size-xl: 28px;
$text-size-l: 22px;
$text-size-m: 18px;
$text-size-body: 16px;
$text-size-s: 14px;
$text-size-xs: 12px;
$text-size-xxs: 10px;

/* -- Font weight -- */
$text-weight-strong: 900;
$text-weight-bold: 700;
$text-weight-medium: 500;
$text-weight-normal: 400;
$text-weight-light: 300;
$text-weight-thin: 100;

/* -- Media query -- */
$scr-xl: "1439px";
$scr-lg: "1199px";
$scr-md: "991px";
$scr-sm: "767px";
$scr-xs: "679px";
