.page-transactions-details {
  
    .transactions-details {

        .page-background {
            padding: 30px 60px;

            @media only screen and (max-width: $scr-md) {
                padding: 30px 10px
            }

        }

        &__cta-container {
            display: flex;
            justify-content: space-between;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 20px;

            @media only screen and (max-width: $scr-xs) {
    
                button {
                    min-width: 80px;
                    font-size: $text-size-xs;
                }
    
            }

        }

        &__tile {
            position: relative;
            margin: 15px;
            display: flex;
            flex:1;
            flex-direction: column;
            justify-content: space-between;

            &:nth-of-type(1) {
                background-color: $c-white;
                border: 1px solid #9687D8;
                color: #777777;
            }

            &:nth-of-type(2) {
                background-color: $c-white;;
                border: 1px solid #87D88F;
                color: #777777;
            }

            &:nth-of-type(3) {
                background-color: #48458D;
                color: $c-white;
            }

            &:nth-of-type(4) {
                background-color: #38CC8C;
                color: $c-white;
            }

        }

        &__tile-title {
            font-size: $text-size-body;
            padding: 10px 15px 0px;
            margin-bottom: 0px;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-s;
            }

        }

        &__tile-subtitle {
            padding: 0px 15px;
            font-size: $text-size-xs;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-xxs;
            }

        }

        &__tile-container {
            display: flex;
            flex-wrap: wrap;
        }

        &__tile-value-container {
            text-align: right;
            padding: 15px;
            white-space: nowrap;
        }

        &__tile-value {
            font-size: $text-size-l;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-m;
            }

        }

        &__tile-currency {
            font-size: $text-size-body;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-s;
            }

        }

        &__tile-line {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 14px;
            width: 100%;
        }

        &__info {
            display: flex;
            flex-direction: row;

            @media only screen and (max-width: $scr-md) {
                flex-direction: column;
            }

        }

        &__info-section {
            display: flex;
            flex: 1;
            flex-direction: column;
            background-color: #FBFBFB;
            margin: 15px;
            padding: 15px;
        }

        &__info-field-value-container {
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
        }

        &__info-field-seperator-container {
            display: flex;
            margin: 0px 5px;
        }

        &__info-seperator {
            font-size: $text-size-s;
        }

        &__info-field {
            width: 130px;
            font-size: $text-size-s;
        }

        &__info-value {
            display: flex;
            flex: 1;
            justify-content: flex-start;
            margin-left: 15px;
            margin-right: 10px;
            margin-top: 2px;
            font-size: $text-size-xs;
            word-break: break-all;
        }

        &__api-request {
            background-color: #FBFBFB;
            margin: 15px;
            padding: 15px;
        }

        &__api-request-title {
            font-size: $text-size-xl;
            margin-bottom: 0;
        }

        &__api-request-subtitle {
            font-size: $text-size-s;
        }

    }

}