.app-info-box {

    &__icon-info-message-container {
        padding: 10px 20px;
        border-radius: 10px;
        border: 2px solid #72A7CF;
        margin: auto;
        display: flex;
        align-items: center;

        svg {
            color: #72A7CF;
            min-width: 54px;
        }

    }

    &__message {
        color: $c-dark-gray;
        font-size: $text-size-m;
        margin-left: 10px;

        @media only screen and (max-width: $scr-xs) {
            font-size: $text-size-body;
        }

    }

}