.app-table{
    margin-bottom: 20px;

    table {
        min-width: 750px;
        width: 100%;
    }

    a {
        text-decoration: underline;
    }

    th {
        font-size: $text-size-s;
        text-align: center;
        background-color: #F6F6F6;
        padding: 10px 15px;
        vertical-align: top;
    }

    td {
        font-size: $text-size-xs;
        text-align: center;
        padding: 0px 12px;
        white-space: nowrap;

        .a-tag {
            text-decoration: underline;
            background-color: transparent;
            border: 0;
            color: #8260F5;
        }
        
    }

    .cell-status {
        border-radius: 20px;
        padding: 3px 10px;
        margin: 10px auto;
        max-width: 100px;

        &--success {
            background-color: #38CC8C;
        }

        &--pending {
            background-color: #C2CC38;
        }

        &--failed {
            background-color: #CC3838;
        }
        
        &--cancelled {
            background-color: #CC3838;
        }

        &--error {
            background-color: #CC3838;
        }
        
    }

    &__pagination {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        border-color: transparent;
        margin-top: 15px;
        padding: 15px;
        font-size: $text-size-s;

        @media only screen and (max-width: $scr-xs) {
            font-size: $text-size-xs;
        }

    }

    &__pagination-page-index {
        border-color: transparent;
    }

    &__pagination-pages-button {
        background-color: $c-white;
        color: #0088CC;
        border:  1px solid lighten($c-light-gray, 20%);
        margin: 5px -1px;
        padding: 5px 15px;

        @media only screen and (max-width: $scr-xs) {
            padding: 5px 12px;
        }

        &--is-active {
            background-color: #0088CC;
            color: $c-white;
        }

        &:hover {
            opacity: 0.7;
        }
        
    }

}