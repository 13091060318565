@import "bootstrap";

// app-view
.app-view {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-width: 320px;

  &.has-header {
    padding-top: $header-height;
  }

}

.app-box-container {
  display: inline-block;
}

.app-content-footer-container{

  &--has-sidebar {
    margin-left: 200px;

    @media only screen and (max-width: $scr-md) {
      margin-left: 150px;
    }
  
    @media only screen and (max-width: $scr-xs) {
      margin-left: 0;
    }

  }
 
}

// app-content
.app-content {
  flex-grow: 1;
}

// app-page
.app-page {
  height: 100%;
  padding: 30px 0px;

  a {
      word-break: break-word;
  }
}

.app-spinner {
}

html,
body {
    height: 100%;
    font-family: "Montserrat";
}

body {
    background-color: $c-body;
}

//common

// app-loading-spinner
.app-loading-spinner {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  line-height: 100vh;
  z-index: 9999;
  background-color: $c-light-gray;
  opacity: 0.6;
}

.btn-container {
  
  &--center {
      text-align: center;
  }

}

.container-align-left {
  @extend .container;
  margin-left: 0;
  margin-right: auto;

  @media only screen and (max-width: $scr-md) {
  margin-left: auto;
  margin-right: auto;
  }

}

.shadow {
  box-shadow: 0px 0px 6px $c-shadow-black;
}

.text-bold {
  font-weight: 600;
}

.app-alert {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99999;

  &__banner {

  }
}

.alert-modal {
  background-color: rgba($c-black, 0.7);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;

  &--hidden {
      display: none;
  }

  .modal-wrapper {
    padding: 15px;
    background-color: $c-white;

    .modal-header {
        flex-direction: column;
        text-align: left;
        justify-content: left;
        font-size: $text-size-l;
        border-bottom: transparent;
    }

    .modal-body {

        p {
            margin: 0;
        }

    }

    .modal-footer {
        padding: 20px;
        text-align: center;
        justify-content: center;
        border-top: transparent;

        a {
            display: block;
            margin-top: 10px;
            text-decoration: underline;
        }

    }

  }

}

.page-background {
  background-color: $c-white;
}

// Bootstrap btn, btn-lg and btn-primary overwrite
.btn {
  min-width: 140px;
  text-transform: uppercase;

  &.btn-lg {
      border-radius: 20px;
      padding: 18px 25px;
      font-size: $text-size-m;
  }

  &.btn-m {
    padding: 7px 15px;
    font-size: $text-size-s;
    border-radius: 15px;
  }

  &.btn-outline {
    color: #5DA4F9;
    background-color: $c-white;
    border-color: #5DA4F9;
  }

  &.btn-fluid {
      width: 100%;
  }

  &.btn-primary {
    color: $c-white;
    background-color: #5DA4F9;
    border-color: #5DA4F9;
  }

  &.btn-primary-purple {
      color: $c-white;
      background-color: $c-primary-purple;
      border-color: $c-primary-purple;
  }

  &.btn-secondary-red {
    color: $c-white;
    background-color: #D96A6A;
    border-color: #D96A6A;
  }

  &.btn-blue {
    color: $c-white;
    background-color: #0088CC;
    border-color: #0088CC;
  }

  &.btn-dark-blue {
    color: $c-white;
    background-color: #4984A1;
    border-color: #4984A1;
  }

  &.btn-green {
    color: $c-white;
    background-color: #38CC8C;
    border-color: #38CC8C;
  }

  &.disabled,
  &:disabled {
    opacity: 0.75;
  }

  &:hover {
    opacity: 0.85;
  }

  &:active {
    opacity: 0.95;
  }

}