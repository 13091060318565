.request-settlement-modal {
    padding: 0 30px;

    @media only screen and (max-width: $scr-md) {
        padding: 0;
        overflow-y: auto;
        align-items: flex-start;
    }
    
    .modal-wrapper {
        padding: 15px;

        .col-3 {

            @media only screen and (max-width: $scr-md) {
                min-width: 50%;
            }

            @media only screen and (max-width: $scr-xs) {
                min-width: 100%;
            }

        }

    }
    
    &__title {
        margin-bottom: 0;
    }

    &__subtitle {
        font-size: $text-size-s;
        margin-bottom: 0;
    }

    &__tile {
        position: relative;
        padding: 5px 15px 20px;
        color: $c-white;
        display: flex;
        flex:1;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    &__tile-title {
        text-align: left;
        font-size: $text-size-l;

        @media only screen and (max-width: $scr-md) {
            font-size: $text-size-body;
        }

    }

    &__tile-subtitle {
        text-align: left;
        font-size: $text-size-s;

        @media only screen and (max-width: $scr-md) {
            font-size: $text-size-xs;
        }

    }

    &__tile-value-container {
        text-align: right;
        margin-top: 15px;
        white-space: nowrap;
    }

    &__tile-value {
        font-size: $text-size-xl;

        @media only screen and (max-width: $scr-md) {
            font-size: $text-size-m;
        }
    }

    &__tile-currency {
        font-size: $text-size-l;

        @media only screen and (max-width: $scr-md) {
            font-size: $text-size-body;
        }

    }

    &__tile-white-line {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 20px;
        width: 100%;
        background-color: white;
        border: 1px solid #48458D;
    }

    &__tac-button {
        background-color: transparent;
        border: transparent;
        color: #007bff;
        text-decoration: underline;
        font-size: $text-size-m;
        position: absolute;
        bottom: -12px;

        &.disabled,
        &:disabled {
          opacity: 0.60;
        }

    }

    &__info {
        margin-top: 40px;
    }

    .btn-container {
        margin: 10px;
    }

}