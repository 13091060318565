.page-transactions {

    .transactions {

        .container-align-left {
            position: relative;
        }

        &__table-container {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 10px 15px;
            background-color: $c-white;
            margin: 10px;
        }

        &__title {
            font-size: $text-size-xl;
            margin: 10px 0px;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-l;
            }

        }

    }

}