.page-my-profile {
  
    .my-profile {

        .page-background {
            padding: 30px 60px;
            height: 75vh;

            @media only screen and (max-width: $scr-md) {
                padding: 30px 10px
            }

        }

        &__logo-info-container {
            display: flex;
            flex-direction: row;

            @media only screen and (max-width: $scr-md) {
                flex-direction: column;
                align-items: center;
            }

        }

        &__logo {
            margin: 15px 30px;
            width: 100px;
        }

        &__info {
            display: flex;
            flex: 1;
            flex-direction: column;
            margin: 15px;
            padding: 30px;
            padding-bottom: 40px;
            border: 1px solid #CECECE;
            max-width: 500px;
        }

        &__info-field-value-container {
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
        }

        &__info-field-seperator-container {
            display: flex;
            margin: 0px 5px;
        }

        &__info-seperator {
            font-size: $text-size-s;
        }

        &__info-field {
            width: 120px;
            font-size: $text-size-s;
        }

        &__info-value {
            display: flex;
            flex: 1;
            justify-content: flex-start;
            margin-left: 15px;
            margin-right: 10px;
            margin-top: 2px;
            font-size: $text-size-xs;
            word-break: break-all;
        }

        &__info-value-reset-button {
            display: flex;
            flex: 1;
            justify-content: flex-start;
            margin-left: 15px;
            margin-right: 10px;
            margin-top: 2px;

            @media only screen and (max-width: $scr-xs) {
                flex-direction: column;
            }

            span {
                word-break: break-all;
                font-size: $text-size-xs;
            }

            button {
                min-width: 80px;
                width: 80px;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 10px;
                font-size: $text-size-xs;
                
                @media only screen and (max-width: $scr-xs) {
                    margin-left: 0;
                }

            }

        }

    }
    
}