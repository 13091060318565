.page-password-reset {
  
    .password-reset {

        // .page-background {
        //     padding: 30px 60px;

        //     @media only screen and (max-width: $scr-md) {
        //         padding: 30px 10px
        //     }

        // }

        &__wrapper {
            max-width: 600px;
            padding: 30px;
            margin: auto;
            background-color: $c-white;
        }

        &__header {
            margin-bottom : 30px;
        }

        &__title {
            font-size: $text-size-l;
            margin-bottom: 0;
        }
    
        &__subtitle {
            font-size: $text-size-s;
            margin-bottom: 0;
        }

        &__form {
            display: flex;
            flex-direction: column;
            padding: 0 100px;
            justify-content: center;

            @media only screen and (max-width: $scr-sm) {
                padding: 0;
            }

        }

        &__info {
            margin: 0 60px 30px 60px;

            @media only screen and (max-width: $scr-sm) {
                margin: 0 0 30px 0;
            }

            span {
                font-size: $text-size-body;
            }

        }

        .btn-container {
            display: flex;
            margin-top: 20px;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: $scr-sm) {
                flex-direction: column;
            }
            
            button {
                margin: 10px;
            }

        }

    }

}