.form-group {
    margin-bottom: 20px;

    &__error {
        display: block;
        color: $c-red;
    }

    &__info {

    }

    &__label {
        margin-bottom: 0;
    }

    &__form-control,
    &__select {
        border-width: 0 0 1px 0;
        border-radius: 0;
        padding-left: 6px;
    }

    &__form-control {

    }

    &__select {

    }

    &__fieldset {
        
    }

    &__field-wrapper {
        position: relative;
    }

}
