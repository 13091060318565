.page-dashboard {

    .dashboard {

        &__title {
            font-size: $text-size-xl;
            margin-top: 10px;
            margin-bottom: 0px;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-l;
            }

        }

        &__subtitle {
            font-size: $text-size-s;
        }

        &__tile {
            position: relative;
            padding: 5px 15px 20px;
            color: $c-white;
            margin: 10px;
            display: flex;
            flex:1;
            flex-direction: column;
            justify-content: space-between;

            &:nth-of-type(1) {
                background-color: #9687D8;
            }

            &:nth-of-type(2) {
                background-color: #8840CF;
            }

            &:nth-of-type(3) {
                background-color: #40CFAD;
            }

        }

        &__tile-title {
            font-size: $text-size-xl;
            margin-bottom: 0px;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-l;
            }

        }
        
        &__tile-container {
            display: flex;
            flex-wrap: wrap;
        }

        &__tile-value-container {
            text-align: right;
            margin: 0px;
            white-space: nowrap;
        }

        &__tile-value {
            font-size: $text-size-xxxl;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-xl;
            }
        }

        &__tile-currency {
            font-size: $text-size-xl;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-l;
            }

        }

        &__tile-white-line {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 20px;
            width: 100%;
            background-color: white;
        }
        
        &__graph-container {
            padding: 10px 15px;
            background-color: $c-white;
            margin: 10px;
        }

        &__graph {
            min-width: 650px;
        }

        &__graph-wrapper {
            overflow: auto;
        }

        &__table-container {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 10px 15px;
            background-color: $c-white;
            margin: 10px;
        }

    }
    
}