.forget-password-modal {
    padding: 0 30px;

    @media only screen and (max-width: $scr-md) {
        padding: 0;
    }
    
    .modal-wrapper {
        padding: 15px;

        .col-6 {

            @media only screen and (max-width: $scr-md) {
                min-width: 50%;
            }

            @media only screen and (max-width: $scr-xs) {
                min-width: 100%;
            }

        }

    }
    
    &__title {
        margin-bottom: 0;
    }

    &__subtitle {
        font-size: $text-size-s;
        margin-bottom: 0;
    }

    .btn-container {
        margin: 10px;
    }

}