.page-my-partner-account {

    .my-partner-account {

        .page-background {
            padding: 30px 45px;

            @media only screen and (max-width: $scr-md) {
                padding: 30px 10px
            }

        }

        &__tile-graph-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 60px;
            padding-bottom: 15px;
            border-bottom: solid 2px $c-border-gray;

            &:last-of-type {
                border-bottom: transparent;
            }

        }
        
        &__tile-container {
            display: flex;
            flex-direction: column;
        }

        &__tile-row {
            display: flex;
        }

        &__tile {
            position: relative;
            display: flex;
            flex:1;
            flex-direction: column;
            justify-content: space-between;
            color: $c-white;
        }

        &__tile-title {
            font-size: $text-size-body;
            padding: 10px 15px 0px;
            margin-bottom: 0px;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-s;
            }

        }

        &__tile-subtitle {
            padding: 0px 15px;
            font-size: $text-size-xs;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-xxs;
            }

        }

        &__tile-value-container {
            text-align: right;
            padding: 15px;
            white-space: nowrap;
        }

        &__tile-value {
            font-size: $text-size-l;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-m;
            }

        }

        &__tile-currency {
            font-size: $text-size-body;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-s;
            }

        }

        &__tile-line {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 14px;
            width: 100%;
            background-color: $c-white;
            border: 1px solid #48458D;
        }

        &__graph-title {
            font-size: $text-size-xl;
            margin-top: 10px;
            margin-bottom: 0px;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-l;
            }

        }

        &__graph-subtitle {
            font-size: $text-size-s;
        }

        &__graph-container {
            padding: 10px 0px;
            background-color: $c-white;
            margin: 10px 0px;
        }

        &__graph {
            min-width: 650px;
        }

        &__graph-wrapper {
            overflow: auto;
        }

        &__api-details {
            display: flex;
            flex-direction: row;
            border: 1px solid #D2D2D2;
            padding: 15px;
            border-radius: 5px;
            
            @media only screen and (max-width: $scr-md) {
                flex-direction: column;
                align-items: center;
            }

        }

        &__api-details-heading {
            padding: 15px;
        }

        &__api-details-title {
            font-size: $text-size-xl;
            margin-bottom: 0px;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-l;
            }

        }

        &__api-details-subtitle {
            font-size: $text-size-s;
            margin-bottom: 0;
        }

        &__api-details-field-value-container {
            display: flex;
            flex-direction: column;
            background-color: #F3F3F3;
            border-radius: 15px;
            padding: 15px;
            margin-top: 10px;
            margin-bottom: 15px;
        }

        &__api-details-field-value {
            text-align: left;
            width: 100%;
        }

        &__api-details-field-value-hide-button-container {
            display: flex;

            @media only screen and (max-width: $scr-md) {
                flex-direction: column;
                align-items: center;
            }
            
            button {
                max-width: 280px;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 10px;

                @media only screen and (max-width: $scr-md) {
                    margin-top: 15px;
                    margin-bottom: 10px;
                }

            }
            
        }

        &__api-details-field {
            white-space: nowrap;
            margin-right: 10px;
        }

        &__api-details-value {
            word-break: break-all;
        }

    }

}