.page-settlements {

    .settlements {

        .page-background {
            padding: 30px 45px;

            @media only screen and (max-width: $scr-md) {
                padding: 30px 10px
            }

        }

        &__tile-container {
            display: flex;
            flex-wrap: wrap;
            margin-left: -10px;
            margin-right: -10px;
        }

        &__title {
            font-size: $text-size-xl;
            margin-top: 10px;
            margin-bottom: 0px;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-l;
            }

        }

        &__subtitle {
            font-size: $text-size-s;
        }

        &__tile {
            position: relative;
            padding: 5px 15px 20px;
            color: $c-white;
            margin: 10px;
            display: flex;
            flex:1;
            flex-direction: column;
            justify-content: space-between;

            &:nth-of-type(1) {
                background-color: #48458D;
            }

            &:nth-of-type(2) {
                background-color: #A45858;
            }

        }

        &__tile-title {
            font-size: $text-size-xl;
            margin-bottom: 0px;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-l;
            }

        }

        &__tile-value-container {
            text-align: right;
            margin: 0px;
            white-space: nowrap;
        }

        &__tile-value {
            font-size: $text-size-xxxl;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-xl;
            }
        }

        &__tile-currency {
            font-size: $text-size-xl;

            @media only screen and (max-width: $scr-md) {
                font-size: $text-size-l;
            }

        }

        &__tile-white-line {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 20px;
            width: 100%;
            background-color: white;
            border: 1px solid #48458D;
        }

        &__table-container {
            display: flex;
            flex: 1;
            flex-direction: column;
            background-color: $c-white;
            margin-top: 20px;
        }

    }

}