@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import "bootstrap/dist/css/bootstrap.min.css";

/* -- common -- */
@import "variables";

/* -- form -- */
@import "./form";

/* -- app -- */
@import "app";

/* -- components -- */
@import "./components/app-header";
@import "./components/app-side-navbar";
@import "./components/app-footer";
@import "./components/app-table";
@import "./components/pages/transactions/transactions-details/callbackAlertModal";
@import "./components/app-info-box";
@import "./components/pages/settlements/requestSettlementModal";
@import "./components/pages/login/forgetPasswordModal";

/* -- pages -- */
@import "./pages/page-login";
@import "./pages/page-reset-password";
@import "./pages/page-dashboard";
@import "./pages/page-transactions";
@import "./pages/transactions/page-transactions-details";
@import "./pages/page-my-partner-account";
@import "./pages/page-settlements";
@import "./pages/page-my-profile";
@import "./pages/my-profile/page-password-reset";
@import "./pages/page-payment";