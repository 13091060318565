
.page-payment {
    padding: 0;
    background-color: $c-white;

    .payment {

        &__header {
            display: flex;
            background-color: #3C5182;
            height: 90px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 10px 30px;
            align-items: center;
            justify-content: space-between;
        }

        &__header-logo-1 {

            img {

                @media only screen and (max-width: $scr-xs) {
                    max-width: 150px;
                }

            }

        }

        &__header-logo-2 {

            img {

                @media only screen and (max-width: $scr-xs) {
                    max-width: 100px;
                }

            }

        }
        
        &__box-wrapper {
            display: flex;
            padding: 40px;

            @media only screen and (max-width: $scr-lg) {
                flex-direction: column;
                padding : 40px 0;
            }

        }

        &__box-left {
            max-width: 600px;
        }

        &__select-payment {
            display: flex;
            flex-direction: column;
        }

        &__info {
            margin-bottom: 40px;
        
            span {
                font-size: $text-size-xs;
            }
        
        }

        &__select-payment-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 30px;
            padding: 0;
            overflow: hidden;
            background-color: white;
            margin-bottom: 15px;
            opacity: .5;
            box-shadow: 0px 0px 4px #DCDCDC;
            height: 180px;

            @media only screen and (max-width: $scr-xs) {
                height: 280px;
            }

            &--is-active {
                opacity: 1;
                box-shadow: 0px 0px 4px #6699F0;
            }

        }

        &__select-payment-logo {
            background-color: white;
            padding: 15px 35px;
            min-width: 150px;
            width: 150px;

            @media only screen and (max-width: $scr-xs) {
                min-width: 80px;
                width: 80px;
                padding: 0px 10px;
              }
            
            img {
                width: 75px;
            }

        }

        &__select-payment-details {
            background-color: #EFEFEF;
            padding: 15px 30px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .btn-container {

                @media only screen and (max-width: $scr-xs) {
                    display: flex;
                    flex-direction: column;
                }

                .btn {
                    text-transform: none;

                    @media only screen and (max-width: $scr-xs) {
                        min-width: auto;
                        margin: 5px;
                    }

                }
    
                .btn-primary {
                    margin-right: 10px;
                }
                
            }

        }

        &__select-payment-details-header {
            display: flex;
            align-items: center;
        }

        &__select-payment-details-header-logo {
            margin-right: 10px;
        }

        &__select-payment-details-header-text {
            font-size: $text-size-m;

            @media only screen and (max-width: $scr-xs) {
                font-size: $text-size-s;
              }

        }

        &__select-payment-details-description {
            font-size: $text-size-xs;
        }

        &__box-right {

        }

        &__bill-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 15px;
            background-color: #EEF1F6;
            margin-top: 52px;
            padding: 0 30px;
            margin-left: 100px;
            min-width: 300px;

            @media only screen and (max-width: $scr-lg) {
                margin-left: 0;
                margin-top: 100px
            }

            .btn-container {

                .btn {
                    text-transform: none;
                }
    
                .btn-primary {
                    margin-bottom: 15px;
                }
                
            }

        }

        &__bill-overlay-container {
            background-color: white;
            margin-top: -52px;
            width: 100%;
            border: 1px solid #E9E9E9;
        }

        &__bill-title {
            background-color: #EEF1F6;
            padding: 10px;
            text-align: center;
            margin-bottom: 0;
        }

        &__bill-values {
            font-size: $text-size-s;
            text-align: left;
            width: 100%;
            padding: 15px 20px;
            border-bottom: 1px solid #E9E9E9;
        }

        &__bill-values-usd {
            text-align: center;
            font-size: $text-size-xl;
        }

        &__bill-values-myr {
            font-size: $text-size-xl;
        }

        &__bill-details {
            padding: 15px;
        }

        &__bill-details-field-value {
            display: flex;
            font-size: $text-size-xs;
            justify-content: space-between;
        }

        &__bill-details-field {

        }

        &__bill-details-value {
            margin-left: 10px;
        }

        &__bill-disclamer {
            font-size: $text-size-xxs;
            margin-top: 15px;
            margin-bottom: 30px
        }

        .form-group{
            margin-right: 5px;
            margin-left: 5px;

            &__label {
                font-weight: $text-weight-bold;
                font-size: $text-size-l;
                margin-bottom: 20px;
            }

            &__form-control {
                border: solid $c-primary-blue 1px;
                height: 63px;
                font-size: $text-size-m;
                border-radius: 15px;
            }

        }

        &__footer {
            height: 220px;
            padding: 25px 45px;
            font-size: $text-size-xs;

            a {
                font-size: $text-size-s;
            }

        }

    }
    
}