.app-side-navbar {
    position: fixed;
    top: $header-height;
    height: 100%;
    width: 200px;
    background-color: lighten($c-body, 2%);
    box-shadow: 0px 3px 6px $c-shadow-black;
    text-align: center;
    padding-bottom: 100px;

    @media only screen and (max-width: $scr-md) {
        width: 100%;
        bottom: 0;
        overflow-y: auto;
        z-index: 999999;
    }

    &__email {
        font-size: $text-size-s;
        margin: 10px 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__transaction-time-date-container {
        margin: 10px 7px;
        padding: 10px 20px;
        background-color: $c-white;
    }

    &__transaction-time {
        font-size: $text-size-body;
        color: $c-primary-blue;
    }

    &__transaction-date {
        color: $c-primary-blue;
        font-size: $text-size-body;
    }

    &__nav-item {
        border: 0;
        padding: 10px 30px;
        color: $c-dark-gray;
        font-size: $text-size-m;
        cursor: pointer;
        margin-bottom: 2px;
        margin-left: 1px;
        background-color: $c-white;

        @media only screen and (max-width: $scr-md) {
            padding: 10px 20px;
            font-size:  $text-size-body;
        }

        &:hover {
            opacity: 0.7;
        }
        
        &--active {
            color: $c-white;
            background-color: $c-light-gray;
        }

        p{
            margin: 0;
        }

    }
  
}

 