.page-login {
    padding: 0;
    background-color: $c-white;

    .login {
        
        &__box-wrapper {
            display: flex;
            height: 100vh;
        }

        &__background-image {
            //todo move this to common
            background-image: url(../../images/pages/page-login/background-image-login.png);
            left: 0;
            top: 0;
            flex :1;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;

            @media only screen and (max-width: $scr-md) {
                display: none;
            }

        }

        &__form {
            display: flex;
            flex-direction: column;
            padding: 30px;
            justify-content: center;

            @media only screen and (max-width: $scr-md) {
                //todo move this to common
                background-image: url(../../images/pages/page-login/background-image-login-reduced-opacity.png);
                left: 0;
                top: 0;
                flex :1;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                background-color: rgba(0, 0, 0, 0.1);
            }

        }

        &__info {
            margin-bottom: 30px;
        }

        &__logo {
            width: 260px;
            margin-bottom: 50px;
        }

        &__forget-password-btn-container {
            
        }

        &__forget-password-button {
            background-color: transparent;
            border: transparent;
            color: #007bff;
            text-decoration: underline;
            font-size: $text-size-m;
    
            &.disabled,
            &:disabled {
              opacity: 0.60;
            }
            
        }

        .form-group{
            margin-right: 5px;
            margin-left: 5px;

            &__label {
                font-weight: $text-weight-bold;
                font-size: $text-size-l;
                margin-bottom: 20px;
            }

            &__form-control {
                border: solid $c-primary-blue 1px;
                height: 63px;
                font-size: $text-size-m;
                border-radius: 15px;
            }

        }

        .btn-container {
            margin-top: 20px;
        }

    }
    
}