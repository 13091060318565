.app-header {
    position: fixed;
    left: 0;
    top: 0;
    padding: 0;
    width: 100%;
    height: $header-height;
    background-color: $c-white;
    z-index: 10;
    box-shadow: 0px 0px 2px $c-shadow-black;

    &__container {
        padding : 0 40px;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: $scr-xs) {
            padding : 0 20px;
        }

    }

    &__sidebar-button-container {
        display: flex;

        @media only screen and (max-width: $scr-xs) {
            flex: 1;
        }

    }

    &__sidebar-button {
        margin-right: 20px;
        border: 0;
        padding: 0;
        background-color: transparent;
        color: $c-dark-gray;
        font-size: $text-size-xxl;
        cursor: pointer;
        
    }

    &__logo {
        max-width: 200px;

        img {
            width: 100%;
        }

        @media only screen and (max-width: $scr-xs) {
            flex: 1;
        }

    }

    &__logout-button-container {
        display: flex;
        justify-content: flex-end;

        @media only screen and (max-width: $scr-xs) {
            flex: 1;
        }

    }

    &__logout-button {
        margin-left: 20px;
        margin-right: 0;

        @media only screen and (max-width: $scr-xs) {
            margin-right: 0;
            min-width: 80px;

            &.btn-m {
                font-size: $text-size-xs;
            }

        }

    }

}
